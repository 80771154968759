<template>
  <div>
    <perseu-card title="Formulário de Zona de Resolução">
      <template slot="content">
        <v-form ref="form">
          <v-text-field label="Nome" v-model.trim="resolutionZone.name" />
          <select-lang v-model="resolutionZone.lang" />
          <select-point
            v-model="resolutionZone.trackingPoint"
            label="Ponto de Rastreio"
            type="0"
          />
          <select-point
            v-model="resolutionZone.impactionPoint"
            label="Ponto de Impactação"
            type="1"
          />
          <perseu-input-image
            label="Imagem da área do Par Trauma"
            v-model="resolutionZone.parTraumaAreaImage"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </div>
</template>

<script>
import { save } from "@/services/resolution-zone-service.js";
import PerseuInputImage from "@/components/shared/PerseuInputImage";

export default {
  components: {
    PerseuInputImage,
    "select-point": () => import("@/components/Points/Select"),
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      resolutionZone: this.value,
    };
  },
  methods: {
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await save(this.resolutionZone);
        this.$toasted.global.defaultSuccess();
        if (this.resolutionZone.id) {
          this.$emit("updated", data);
        } else {
          this.$emit("created", data);
        }
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
